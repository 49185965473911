import * as userService from "@/_services";
import { backendConfig, processTokens, router } from "@/_helpers";
// Because of TS, deep dependency...

import { Module } from "vuex";
import { store } from ".";
import * as localStorageService from "@/_services/local-storage.service";
import Axios, { AxiosResponse } from "axios";
import { setUser as sentrySetUser } from "@sentry/browser";
import axios from "axios";

export enum Status {
  Initial = "Initial",
  Denied = "denied",
  Recovering = "recovering",
  Error = "error",
  Logging = "logging",
  Registered = "registered",
  Success = "success",
  Registering = "registering",
  Changing = "changing",
  LoadingInventoriesCompany = "loading-inventories-company",
  LoadingIventoriesForm = "loading-inventories-forms",
}

const accountModule: Module<any, any> = {
  /*
   * We could store the user access token/refresh token in vuex store,
   * but if the user leaves our application, all of the data in
   * the vuex store disappears.
   * To ensure we allow the user to return to the application within
   * the validity time of the token and not have to log in again,
   * we have to keep the token in the browser localStorage.
   */

  namespaced: true,
  state: {
    status: Status.Initial,
    access_token:
      localStorageService.readFromStorage(backendConfig.accessToken) || "",
    refresh_token:
      localStorageService.readFromStorage(backendConfig.refreshToken) || "",
    // JWT, Bearer, ...
    token_type: null,
    // tenant, employee, technician, ...
    user_type: null,
    must_change_password: false,
    user: {},
    firstName: null,
    lastName: null,
    allowed_companies: [],
    AllowedCompaniesFreshness: null,
  },

  actions: {
    login({ dispatch, commit }, { username, password }) {
      commit("loginRequest", { username });

      userService.login(username, password).then(
        (data: any) => {
          commit("loginSuccess", data);
          dispatch("alert/loginClear", "", { root: true });

          // Check if the user must change password
          if (data.must_change_password) {
            router.push({
              path: "/change",
              query: router.currentRoute.query,
            });
          }

          // Get user profile
          userService
            .getUserProfile("user_type" in data ? data.user_type : undefined)
            .then(
              (user: any) => {
                console.log("[account] profile:", user);
                commit("profileSuccess", user);

                // Check if the user must change password
                if ("changePassword" in user && user.changePassword) {
                  router.push({
                    path: "/change",
                    query: router.currentRoute.query,
                  });
                } else {
                  // Navigate to home page
                  router.push({
                    path: "/",
                    query: router.currentRoute.query,
                  });

                  setTimeout(() => {
                    dispatch("alert/loginClear", "", { root: true });

                    // display success message after route change completes
                    dispatch("alert/success", router.app.$t("users.ok_login"), {
                      root: true,
                    });
                    dispatch("loadCompaniesInventory", true);
                  });
                }
              },
              (error: any) => {
                console.error(error);
                dispatch("logout", router.app.$t(error));
                commit("loginFailure", error);
                dispatch("alert/loginAlert", error, {
                  root: true,
                });
              }
            );
        },
        (error: any) => {
          dispatch("logout", router.app.$t(error));
          commit("loginFailure", error);
          dispatch("alert/loginAlert", router.app.$t(error), { root: true });
        }
      );
    },

    logout({ commit, dispatch }, message) {
      userService.logout();
      commit("logout", message);

      // Navigate to login page if it is not the current page
      if (router.currentRoute.path !== "/login") {
        router
          .push({
            path: "/login",
            query: router.currentRoute.query,
          })
          .catch((error: any) => {
            console.error("Login page is not available!", error);
          });
      }
    },

    register({ dispatch, commit }, user) {
      commit("registerRequest");

      userService.register(user).then(
        (user: any) => {
          commit("registerSuccess", user);

          // Navigate to login page
          router.push({
            path: "/login",
            query: router.currentRoute.query,
          });
          setTimeout(() => {
            dispatch("alert/loginClear", "", { root: true });

            // display success message after route change completes
            dispatch("alert/success", router.app.$t("users.ok_register"), {
              root: true,
            });
          });
        },
        (error: any) => {
          commit("registerFailure");
          dispatch("alert/loginAlert", router.app.$t(error), { root: true });
        }
      );
    },

    recoverPassword({ dispatch, commit }, { username }) {
      commit("recoverRequest", { username });

      userService.recover(username).then(
        (user: any) => {
          commit("recoverSuccess", user);

          // Navigate to login page
          router.push({
            path: "/login",
            query: router.currentRoute.query,
          });
        },
        (error: any) => {
          commit("recoverFailure", error);
          dispatch("alert/error", error);
        }
      );
    },

    changePassword({ dispatch, commit }, { old_password, new_password }) {
      console.log("Changing");
      commit("changePassword");
      userService.changePassword(old_password, new_password).then(
        (user: any) => {
          console.log("Change called", user);
          if (user.success !== true) {
            return;
          }
          commit("changedPassword");
          router.push("/");
        },
        () => {
          console.log("Error");
        }
      );
    },

    setLocale({ dispatch, commit }, locale) {
      commit("setLocale", locale.code);
      /*
      dispatch(
        "alert/success",
        router.app.$t("users.ok_language", {
          code: locale.code,
          name: locale.name,
        }),
        {
          root: true,
        }
      );
      */
    },
    refreshToken: async ({ dispatch, commit, state }) => {
      if (!state.refresh_token) {
        await dispatch("logout");
        return;
      }
      const refreshToken = state.refresh_token;
      const authorizationTokenType = state.token_type;

      // allow token refresher to set a token
      const config = {
        headers: {
          Authorization: `${authorizationTokenType} ${refreshToken}`,
        },
      };
      try {
        await Axios.post(
          store.getters["apiConfig/refreshTokenUrl"],
          { refresh: refreshToken },
          config
        ).then((response: AxiosResponse<any>) => {
          if (response.status === 200) {
            const { access, refresh } = processTokens(response.data);
            commit("setAccessToken", access);
            commit("setRefreshToken", refresh);
          }
        });
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.error(e.response);
        }
      }
    },

    loadCompaniesInventory: async (
      { commit, dispatch, state },
      force = false
    ) => {
      // console.log("states is ", state.allowed_companies, state.AllowedCompaniesFreshness + 60*1000 > Date.now())
      // 8H cache
      if (
        state.allowed_companies.length > 0 &&
        state.AllowedCompaniesFreshness + 8 * 60 * 60 * 1000 > Date.now() &&
        !force
      ) {
        console.log("[account] companies inventories from cache");
        // refresh forms if necessary
        state.allowed_companies.forEach((allowedCompany: number) => {
          console.log(
            "Dispatch loadCompaniesForm with param: ",
            allowedCompany
          );
          dispatch("inventory/loadCompanyForm", allowedCompany, {
            root: true,
          });
        });
        return state.allowed_companies;
      } else {
        console.log("[account] companies inventories from internet");
        try {
          userService
            .getUserAllowedCompanyInventories()
            .then((response) => response.data)
            .then((content) => {
              console.log("content", content);
              const companies = content.company_with_inventories;
              commit("AllowedCompanyInventories", companies);
              dispatch(
                "premises/setAllowedBusiness",
                content.company_with_inventories,
                { root: true }
              );
              console.log("Preparing to update inventories form !", companies);
              companies.forEach((allowedCompany: number) => {
                console.log(
                  "Dispatch loadCompaniesForm with param: ",
                  allowedCompany
                );
                dispatch("inventory/loadCompanyForm", allowedCompany, {
                  root: true,
                });
              });
            });
        } catch (e) {
          if (axios.isAxiosError(e)) {
            console.error("Error loading inventory: ", e.response);
          }
        }
      }
    },

    userDenied({ commit }, message) {
      commit("userDenied", message);
    },
  },

  getters: {
    isLoggedIn: (state) => !!state.access_token,
    canRefresh: (state) => !!state.refresh_token,
    authTokenType: (state) => state.token_type,
    mustChangePassword: (state) => state.must_change_password,
    status: (state) => state.status,
    isAuthorized: (state) => {
      return state.status && state.status !== "denied";
    },
    friendlyName: (state) => {
      // Returns, in order of preference, prénom+nom, prénom, nom, sinon Inconnu
      return state.firstName
        ? state.firstName && state.lastName
          ? `${state.firstName} ${state.lastName}`
          : state.firstName
          ? state.firstName
          : state.lastName
          ? state.lastName
          : router.app.$t("users.unnamed")
        : router.app.$t("users.unconnected");
    },
    company: (state) => {
      return state.user ? state.user.company : null;
    },
    companyLogo: (state) => {
      return state.user ? state.user.companyLogo : null;
    },
    role: (state) => {
      return state.user ? state.user.role : "";
    },
    layout: (state) => {
      return state.user ? state.user.layout : "";
    },
    accessToken: (state) => state.access_token,
    refreshToken: (state) => state.refresh_token,
  },

  mutations: {
    recoverRequest(state, user) {
      state.status = Status.Recovering;
      state.user = user;
    },
    recoverSuccess(state, user) {
      state.status = Status.Recovering;
      state.user = user;
    },
    recoverFailure(state) {
      state.status = Status.Error;
      state.user = null;
    },
    loginRequest(state, user) {
      state.status = Status.Logging;
      state.user = user;
    },
    loginSuccess(state, data) {
      state.status = Status.Success;

      // Check received tokens and store them in the local storage
      if (processTokens(data, process.env.NODE_ENV === "development")) {
        state.token_type = data.token_type;
        state.user_type = data.user_type ? data.user_type : "tenant";
        store.dispatch("apiConfig/setApiProfile", state.user_type);

        state.must_change_password = data.must_change_password;
        state.access_token =
          localStorageService.readFromStorage(backendConfig.accessToken) || "";
        state.refresh_token =
          localStorageService.readFromStorage(backendConfig.refreshToken) || "";
      } else {
        state.status = Status.Error;
      }
    },
    profileSuccess(state, user) {
      state.user = user;
      // Update the login received information
      state.must_change_password = state.user.changePassword;
      if (user?.firstName) {
        state.firstName = user.firstName;
      } else if (user?.first_name) {
        state.firstName = user.first_name;
      }

      if (user?.lastName) {
        state.lastName = user.lastName;
      } else if (user?.last_name) {
        state.lastName = user.last_name;
      }
      sentrySetUser({ email: user.email });
    },
    loginFailure(state) {
      state.status = Status.Error;
      state.user = null;
    },
    logout(state, message) {
      state.status = Status.Initial;
      state.user = null;
      state.access_token = "";
      state.refresh_token = "";
      state.allowed_companies = [];
      state.AllowedCompaniesFreshness = 0;
      // Clear an existing token refresh timer
      if (state.refresh_task) {
        clearTimeout(state.refresh_task);
      }
      if (message) {
        console.warn("Logout because: " + message);
      }
    },
    registerRequest(state) {
      state.status = Status.Registering;
      state.user = null;
    },
    registerSuccess(state, user) {
      state.status = Status.Registered;
      state.user = user;
    },
    registerFailure(state) {
      state.status = Status.Error;
      state.user = null;
    },
    setLocale(state, code) {
      if (state.user) {
        state.user.languageCode = code;
      }
    },
    userDenied(state, message) {
      console.warn(
        "Access denied for the current logged-in user, component: " + message
      );
      state.status = "denied";
    },
    changePassword(state) {
      state.status = Status.Changing;
    },
    changedPassword(state) {
      state.status = Status.Success;
      state.must_change_password = false;
    },
    setRefreshToken: (state, refreshToken) => {
      state.refresh_token = refreshToken;
    },
    setAccessToken: (state, accessToken) => {
      state.access_token = accessToken;
    },
    AllowedCompanyInventories: (state, allowedCompanies) => {
      state.allowed_companies = allowedCompanies;
      state.AllowedCompaniesFreshness = Date.now();
    },
  },
};
export default accountModule;
