
/**
 * This component  is used to scan NFC tags and to trigger a search in the Premises component.
 * If the tag is not associated to a Premise, the user is redirected to the Premises component.
 *
 * onMounted this start scanning a tag.
 * When the tag is read, the trigger a search in the Premises component, on the tag uuid.
 * onUnmounted this stop scanning a tag.
 */

import { defineComponent } from "vue";
import { mapState } from "vuex";
import { NfcState } from "@/_store/nfc.module";

export default defineComponent({
  name: "NFCScanner",
  emits: ["tagRead"],
  computed: {
    NfcState() {
      return NfcState;
    },
    ...mapState("NFCModule", ["hasNFC", "state"]),
  },
  mounted() {
    this.$store.dispatch("NFCModule/readNfc").then((tag) => {
      console.log("tag", tag);
      this.$store.dispatch("NFCModule/setScanDateNow");
      this.$emit("tagRead", tag);
      this.$store.dispatch("NFCModule/stopNfc");
    });
  },
  beforeUnmount() {
    this.$store.dispatch("NFCModule/stopNfc");
  },
  methods: {
    buttonClick() {
      // this.$store.dispatch("NFCModule/read");
    },
  },
});
