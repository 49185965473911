/*
 * Logs services
 */

import { backendConfig } from "@/_helpers";
import { _axios } from "@/plugins/axios";

export const logService = {
  getAll,
  raise,
};

function raise(equipment, comments, picture, date, urgent, engieInter = null) {
  let payload = {
    selectedEquipment: equipment,
    comments: comments,
    picture: picture,
    date: date,
    urgent: urgent,
    engie: null,
  };
  if (engieInter !== null) {
    payload.engie = engieInter;
  }

  return _axios
    .post(`${backendConfig.alertEndpoint}`, payload)
    .then((response) => response.data);
}

function getAll(filter) {
  let params = {};
  if (filter) {
    if ("premiseId" in filter && filter.premiseId) {
      params.premise = filter.premiseId;
    } else {
      params = filter;
    }
  }
  console.log("[logs] Filtering parameters", params);

  return _axios
    .get(`${backendConfig.logsEndpoint}`, params)
    .then((response) => response.data);
}
