/*
 * Buildings services
 */

import { backendConfig } from "@/_helpers";
import { _axios } from "@/plugins/axios";

export const buildingService = {
  getAll,
  getBuildingByID,
  getBuildingByURL,
};

type Building = {
  id?: number;
  url: string;
  name: string;
  business: string;
  agency: string;
  street1: string;
  street2: string;
  city: string;
  country: string;
  zip_code: string;
  hp1: string;
  hp2: string;
  hp3: string;
  hp4: string;
  parent: string;
  latitude: number | null;
  longitude: number | null;
  equipments: string[];
  created: string;
  updated: string;
};

function cleanUpBuilding(building: Building): Building {
  let buildingId = null;
  if (building.id === undefined) {
    const url_split = building.url.split("/");
    buildingId = url_split[url_split.length - 1];
    // add it to the building
    // parse the id to an int
    building.id = parseInt(buildingId, 10);
  }
  return building;
}

function getBuildingByURL(url: string) {
  return _axios.get(url).then((response) => {
    return cleanUpBuilding(response.data);
  });
}

function getBuildingByID(id: number | string) {
  if (typeof id === "number") {
    id = id.toString();
  }
  return getBuildingByURL(
    backendConfig.buildingEndpoint.replace("<building_id>", id)
  );
}

function getAll(params?: Record<string, string>) {
  return _axios
    .get(`${backendConfig.buildingsEndpoint}`, { params: params })
    .then((response) => response.data);
}
