/*
 * Premises services
 */

import { backendConfig } from "@/_helpers";
import { store } from "@/_store";
import { _axios } from "@/plugins/axios";

export const premiseService = {
  getAll,
  getPremisesByID,
  getPremisesByURL,
};

function getPremisesByID(id: number | string) {
  return getPremisesByURL(`${backendConfig.premisesEndpoint}/${id}`);
}

function getPremisesByURL(url: string) {
  return _axios.get(url).then((response) => response.data);
}

function getAll(filter: any) {
  console.log("got filter", filter);
  const urlParams = new URLSearchParams(window.location.search);
  const params: Record<string, string | number> = {};

  if (filter) {
    if ("gps" in filter && filter.gps) {
      params[
        "location"
      ] = `${filter.gps.latitude},${filter.gps.longitude},${filter.gps.distance}`;
    }
    if ("business" in filter && filter.business) {
      params["business"] = filter.business;
      if (urlParams.has("business")) {
        console.log(
          `[business] using the forced business:: ${urlParams.get("business")}`
        );
        const business = urlParams.get("business");
        if (business) {
          params["business"] = business;
        }
      }
    }
    if ("text" in filter && filter.text) {
      // if the text start with a # then it's a tag, use the uid param
      if (filter.text.startsWith("#")) {
        // remove the #
        let tag = filter.text.substring(1);
        // remove all the `:` in the variable
        tag = tag.replace(/:/g, "");
        params["tag"] = tag;
      } else {
        // normal query
        params["q"] = filter["text"];
      }
    }
    if ("category" in filter && filter.category) {
      params["category"] = filter.category;
    }
    if ("type" in filter && filter.type) {
      params["type"] = filter.type;
    }
    if ("page" in filter && filter.page) {
      params["page"] = filter["page"];
    }
    if ("limit" in filter && filter.limit) {
      // map to the paginator limit name
      params["page_size"] = filter["limit"];
    }
    if ("business_id" in filter && filter.business_id) {
      params["business_id"] = filter["business_id"].join(",");
    }
  }

  if (params.page_size === undefined) {
    // map to the paginator limit name
    params["page_size"] = 10;
  }
  if (params.page === undefined) {
    params["page"] = 1;
  }

  if (!filter?.gps) {
    // if not `q` or `uid` return empty
    if (!("q" in params) && !("tag" in params)) {
      return Promise.resolve({
        count: 0,
        results: [],
      });
    }

    // if is defined and `q` is empty return empty
    if ("q" in params && !params["q"]) {
      // Don't do search
      return Promise.resolve({
        count: 0,
        results: [],
      });
    }
    // do the same for `uid`
  } else if ("tag" in params && !params["tag"]) {
    // Don't do search
    return Promise.resolve({
      count: 0,
      results: [],
    });
  }

  // cancel if one is in flight
  console.log("Canceling a request");
  const controller = new AbortController();
  store.commit("premises/addRequest", controller);

  return _axios
    .get(`${backendConfig.premisesEndpoint}`, {
      signal: controller.signal,
      params: params,
    })
    .then((response) => response.data)
    .catch((error) => {
      // This is a normal error, we can cancel the request during the loading
      if (error?.name === "AbortError" || error?.message === "canceled") {
        console.log("Canceling request");
      } else {
        console.error("[premises] get error:", error);
      }
    });
}
