/*
 * Equipments services
 */

import { backendConfig } from "@/_helpers";
import { _axios } from "@/plugins/axios";

export const equipmentService = {
  getTypes,
  getForms,
  getAll,
  raise,
  update,
  create,
};

function raise(equipment, comments, picture, date, urgent) {
  const payload = {
    selectedEquipment: equipment,
    comments: comments,
    picture: picture,
    date: date,
    urgent: urgent,
  };

  return _axios
    .post(`${backendConfig.alertEndpoint}`, payload)
    .then((response) => response.data);
}

function create(payload) {
  console.log("[equipments] creating...", payload);

  return _axios
    .post(`${backendConfig.equipmentsEndpoint}`, payload)
    .then((response) => {
      if (response.data.success) {
        return Promise.resolve(response.data);
      }
      console.error("[equipments] creation error:", response.data.errors[0]);
      return Promise.reject(response.data.errors[0]);
    });
}

function update(equipmentId, payload) {
  console.log("[equipments] updating...", equipmentId, payload);

  return _axios
    .patch(`${backendConfig.equipmentEndpoint}/${equipmentId}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.error("[equipments] update error:", error);
    });
}

function getForms() {
  console.log("[equipments] getting forms...");

  return _axios
    .get(`${backendConfig.equipmentsFormsEndpoint}`)
    .then((response) => response.data);
}

function getTypes() {
  console.log("[equipments] getting types...");

  return _axios
    .get(`${backendConfig.equipmentsTypesEndpoint}`)
    .then((response) => response.data);
}

function getAll(filter) {
  let params = {};
  if (filter) {
    if ("premiseId" in filter && filter.premiseId) {
      params["premises_id"] = filter.premiseId;
    }
    if ("inventory" in filter && filter.inventory) {
      // Get only equipments that are to be inventoried
      params["inventory"] = "true";
    }
  }
  console.log("[equipments] all, filtering parameters", params);

  return _axios
    .get(`${backendConfig.equipmentsEndpoint}`, { params: params })
    .then((response) => response.data);
}
