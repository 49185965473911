/*
 * Define the application routes:
 * - main routes
 * - login and register pages
 *
 * Check user authentication
 */

import Vue from "vue";
import Router from "vue-router";
import { store } from "@/_store";

Vue.use(Router);

function lazyLoadView(view) {
  return () => import(`../views/${view}.vue`);
}

export const router = new Router({
  mode: "history",
  routes: [
    { path: "/", component: lazyLoadView("HomePage") },
    { path: "/equipment/:token", component: lazyLoadView("EquipmentPage") },
    { path: "/login", component: lazyLoadView("LoginPage") },
    { path: "/logout", redirect: lazyLoadView("LoginPage") },
    { path: "/change", component: lazyLoadView("PasswordChange") },
    { path: "/register", component: lazyLoadView("RegisterPage") },
    { path: "/lost_password", component: lazyLoadView("LostPasswordPage") },

    // otherwise redirect to home
    { path: "*", redirect: "/" },
  ],
});

/*
 * Before each navigation, check if the user is logged in
 */
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register",
    "/me",
    "/lost_password",
    "/change",
  ];
  const authRequired =
    !publicPages.includes(to.path) && !to.path.startsWith("/equipment/");
  const isLoggedIn = store.getters["account/isLoggedIn"];
  const mustChangePassword = store.getters["account/mustChangePassword"];

  if (to.path === "/logout") {
    // disconnecting user before redirect
    store.dispatch("account/logout", "Disconnecting");
    return next({
      path: "/login",
      query: router.currentRoute.query ? router.currentRoute.query : null,
    });
  }

  if (to.path !== "/change" && isLoggedIn && mustChangePassword) {
    return next("/change");
  }

  // prevent disconnecting logged user when loading a /login page
  if (to.path === "/login" && isLoggedIn) {
    return next("/");
  }

  if (authRequired && !isLoggedIn) {
    if (!!Object.keys(to.query).length) {
      return next({ path: "/login", query: to.query });
    }
    return next("/login");
  }

  // Propagate query parameters if some exist
  if (!!Object.keys(to.query).length && !!Object.keys(from.query).length) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }

  // if(!hasQueryParams(to) && hasQueryParams(from)){
  //   next({name: to.name, query: from.query});
  // } else {
  //   next()
  // }
});
