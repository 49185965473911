<template>
  <viz-base-tile :id="id" :data="data" :premised="premised" :manual="manual">
    <template #name>
      <cite>I am a Logs widget #{{ id }} : {{ data }}</cite>
    </template>

    <template #body>
      <b-card-text>
        <em v-if="logs.status === 'loading'">
          <font-awesome-icon icon="spinner" size="2x" spin></font-awesome-icon>
          &nbsp; {{ $t("actions.t_loading") }}
        </em>
        <div v-else-if="logs.status === 'error'" class="m-3 bg-danger">
          <h1>Could not get logs: {{ logs.error }}!</h1>
        </div>
        <div v-else-if="logs.status === 'success' && logs.items">
          <div v-if="logs.items.length > 0">
            <b-table
              :id="`table-logs-${id}`"
              :sticky-header="data.tableHeaderCss"
              :caption="data.tableCaption"
              caption-top
              :per-page="perPage"
              :current-page="currentPage"
              :items="logsList"
              :fields="fields"
              striped
              hover
              :responsive="data.tableResponsive"
              style="cursor: pointer"
              @row-clicked="
                (item) => $set(item, '_showDetails', !item._showDetails)
              "
            >
              <!-- html content for the timestamp -->
              <template #cell(estimated)="data">
                <strong v-if="data.value">{{ formatHuman(data.value) }}</strong>
                <strong v-else>{{ $t("logs.wait_planning") }}</strong>
                <br />
                {{ data.item.customer_description.substr(0, 100) }}
              </template>

              <template #cell(maintenance)="data">
                <b-img
                  v-if="data && data.value"
                  :src="data.value.logo"
                  :alt="data.value.name"
                  width="50px"
                  class="text-center"
                ></b-img>
              </template>

              <!-- html content for the status (completed if end is set)-->
              <template #cell(end)="data">
                <StatusBox
                  :level="data.item.level"
                  :state="data.value !== null && data.value !== ''"
                  :maintenance="data.item.maintenance"
                ></StatusBox>
              </template>
<!--
              <template #cell(level)="data">
                <StatusBox
                  :level="data.value"
                  :maintenance="data.item.maintenance"
                ></StatusBox>
              </template>
-->

              <!-- html content for the detailed row -->
              <template #row-details="row">
                <b-card>
                  <b-list-group flush>
                    <b-list-group-item
                      v-for="(value, key) in row.item"
                      v-if="
                        isDisplayed(key) &&
                        value !== null &&
                        !(key === 'documents' && !value.length)
                      "
                      :key="key"
                    >
                      <b-row v-if="key === 'equipment'">
                        <b-col sm="4" class="text-sm-left">
                          <b>{{ $t("logs.fields.equiplabel") }}</b>
                        </b-col>
                        <b-col class="text-sm-left">{{
                          value.type.name
                        }}</b-col>
                      </b-row>
                      <b-row v-else-if="key === 'level_label'">
                        <b-col sm="4" class="text-sm-left">
                          <b>{{ $t("logs.fields.level_label") }}</b>
                        </b-col>
                        <b-col class="text-sm-left">{{
                          value
                        }}</b-col>
                      </b-row>
                      <b-row v-else-if="key === 'timestamp'">
                        <b-col sm="4" class="text-sm-left">
                          <b>{{ $t("logs.fields.timestamp") }}</b>
                        </b-col>
                        <b-col class="text-sm-left">{{
                          formatApiDate(value)
                        }}</b-col>
                      </b-row>
                      <b-row v-else-if="key === 'end'">
                        <b-col sm="4" class="text-sm-left">
                          <b>{{ $t("logs.fields.end") }}</b>
                        </b-col>
                        <b-col class="text-sm-left">{{
                          formatApiDate(value)
                        }}</b-col>
                      </b-row>
                      <b-row
                        v-else-if="
                          key === 'documents' && value && value.length > 0
                        "
                      >
                        <b-col sm="4" class="text-sm-left">
                          <b>{{ $t("logs.fields.pictures") }}</b>
                        </b-col>
                        <b-col
                          v-b-modal="'photo-modal-' + row.item['id']"
                          class="text-sm-left"
                        >
                          <font-awesome-icon
                            icon="images"
                            size="1x"
                          ></font-awesome-icon>
                          {{ $t("logs.fields_content.pictures") }}</b-col
                        >
                        <PhotoCarousel
                          :id="row.item['id']"
                          :photos="value"
                        ></PhotoCarousel>
                      </b-row>
                      <b-row v-else-if="key === 'technician_comment'">
                        <b-col sm="4" class="text-sm-left">
                          <b>{{ $t("logs.fields.tech_comment") }}</b>
                        </b-col>
                        <b-col class="text-sm-left">{{
                          value
                        }}</b-col>
                      </b-row>
                      <!-- only if translation is not an empty string -->
<!--
                      <b-row v-else-if="key === 'maintenance'">
                        <b-col sm="4" class="text-sm-left">
                          <b>{{ $t("logs.fields.maintenance") }}</b>
                        </b-col>
                        <b-col>
                          <b-img
                            :src="value.logo"
                            :alt="`${value.name}`"
                            img-fluid
                            height="32"
                          ></b-img>
                        </b-col>
                      </b-row>
-->
                      <b-row v-else>
                        <b-col sm="4" class="text-sm-left">
                          <strong>{{ $t(`logs.fields.${key}`) }}</strong>
                        </b-col>
                        <b-col class="text-sm-left">{{ value }}</b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </template>
            </b-table>

            <b-pagination
              v-if="logs.items && logs.items.length > perPage"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              hide-goto-end-buttons
              size="sm"
              :aria-controls="`table-logs-${id}`"
              align="right"
            ></b-pagination>
          </div>
          <div v-else class="text-warning text-center">
            {{ $t("logs.no_items") }}
          </div>
        </div>
        <div v-else class="text-warning">
          {{ $t("logs.no_items") }}
        </div>
      </b-card-text>
    </template>
  </viz-base-tile>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
// Font Awesome icons importation
import { library } from "@fortawesome/fontawesome-svg-core";
// Import used icons
import {
  faImages,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
library.add(faImages, faCaretUp, faCaretDown);

export default {
  name: "VizLogs",
  components: {
    VizBaseTile: () => import("./BaseTile"),
    StatusBox: () => import("../StatusBox"),
    PhotoCarousel: () => import("../PhotoCarousel"),
  },
  props: {
    id: {
      type: String,
      default: "1",
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    premised: {
      type: Boolean,
      default: false,
    },
    manual: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 500,
      // The list of the displayed field in the details pane
      displayedFields: [
        "level_label",
        "description",
        "cust_convenience",
        "equipment",
        "timestamp",
        "end",
        "documents",
        "customer_description",
//        "maintenance",
        "technician_comment",
      ],
      // The list of most useful fields
      fields: [
        {
          key: "end",
          label: this.$t("logs.fields.end"),
          tdAttr: {
            style: "max-width: 50px;",
          },
        },
        {
          key: "estimated",
          label: this.$t("logs.fields.estimated"),
          sortable: true,
        },
        {
          key: "maintenance",
          label: this.$t("logs.fields.maintenance"),
//          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      logs: (state) => state.logs,
      chosenPremise: (state) => state.premises.selected,
    }),
    rows() {
      return this.logs.items.length;
    },
    logsList: function () {
      const copy = (list) => Object.assign([], list);
      let log_list = copy(this.logs.items);
      log_list = log_list.sort((a, b) => {
        const dateA = new Date(a.estimated ? a.estimated : a.timestamp),
          dateB = new Date(b.estimated ? b.estimated : b.timestamp);
        return dateA - dateB;
      });
      return log_list;
    },
  },
  watch: {
    // Changed the premise to inventory
    chosenPremise: function (newVal, oldVal) {
      if (!this.premised) {
        return;
      }

      if (newVal === null) {
        // No more selected premise
        console.log("[logs] no more selected premise");
        this.clearAll();
      } else {
        // Newly selected premise
        // Get all the available logs for the selected premise
        console.log("[logs] fetching...");
        this.getAll(this.chosenPremise.name);
      }
    },
  },
  created() {
    console.log(
      `[logs] Premised: ${this.premised}, chosen: '${
        this.chosenPremise && this.chosenPremise.name
      }'`
    );

    if (this.manual) {
      return;
    }

    if (!this.premised || (this.premised && this.chosenPremise)) {
      console.log("[logs] Getting all intervention logs");
      // Get all the available logs
      this.getAll();
    }
  },
  methods: {
    ...mapActions("logs", ["getAll", "clearAll"]),
    getName(equipmentData) {
      return equipmentData;
    },
    isDisplayed(key) {
      return this.displayedFields.indexOf(key) >= 0;
    },
    formatApiDate(apiDate) {
      if (apiDate == null) return "";
      // parseDate
      let myDate = new Date(apiDate);
      // Create a moment object in the CET timezone
      if (this.$i18n.locale === "fr") {
        return format(myDate, "dd/MM/yyyy HH:mm", { locale: fr });
      } else {
        return format(myDate, "dd/MM/yyyy HH:mm");
      }
    },
    formatHuman(apiDate) {
      if (apiDate == null || apiDate === "") return "";
      // parse date
      let myDate = new Date(apiDate);
      // Create a moment object in the CET timezone
      let period;
      let options = {};
      if (this.$i18n.locale === "fr") {
        options = { locale: fr };
        if (myDate.getHours() >= 12) {
          period = "après-midi";
        } else {
          period = "matin";
        }
      } else {
        if (myDate.getHours() >= 12) {
          period = "afternoon";
        } else {
          period = "morning";
        }
      }
      return format(myDate, `dd/MM/yyyy '${period}'`, options);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Known logs"
  },
  "fr": {
    "title": "Dernières interventions"
  }
}
</i18n>
