"use strict";

import Vue from "vue";
import axios from "axios";
import { backendConfig } from "@/_helpers";
import { store } from "@/_store";

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = backendConfig.tenant.apiUrl;

// Create a new axios instance
export const _axios = axios.create({
  baseURL: backendConfig["tenant"].apiUrl,
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
});

// Pre query
_axios.interceptors.request.use(
  function (config) {
    config.baseURL = store.getters["apiConfig/baseApiURL"];
    const token = store.getters["account/accessToken"];
    const authorizationTokenType = store.getters["account/authTokenType"];

    if (token) {
      // allow token refresher to set a token
      config.headers["Authorization"] = `${authorizationTokenType} ${token}`;
    }
    if (
      typeof process.env.VUE_APP_API_X_USER_AGENT !== "undefined" &&
      process.env.VUE_APP_API_X_USER_AGENT !== ""
    ) {
      if (!("X-User-Agent" in config.headers)) {
        // Add an X-User-Agent header to allow catching application specific traffic on the server side
        config.headers["X-User-Agent"] = `${process.env.APP_NAME || "Vizavy"}/${
          process.env.APP_VERSION || "X.Y.Z"
        }`;
      }
    }

    if (!("Content-Type" in config.headers)) {
      // Add an X-User-Agent header to allow catching application specific traffic on the server side
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  function (error) {
    console.log("Problem with config");
    // Do something with request error
    return Promise.reject(error);
  }
);

// Post request, allow to refresh
// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      originalRequest.url.includes("/refresh")
    ) {
      // un log the person
      await store.dispatch("account/logout");
      return Promise.reject(error);
    } else if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // await execution of the store async action before
      // return
      await store.dispatch("account/refreshToken");
      return _axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
