import { businessService } from "@/_services";

const state = {
  status: "",
  items: [],
  itemsCount: 0,
  itemsFreshness: 0,
};

const actions = {
  getAll({ dispatch, commit }) {
    commit("getAllRequest");

    businessService.getAll().then(
      (data) => {
        console.log("[businesses] Got:", data);
        commit("getAllSuccess", data);
      },
      (error) => {
        commit("getAllFailure", error);
        if (error && error !== "Unauthorized") {
          dispatch("alert/error", error, { root: true });
        }
      }
    );
  },

  clearAll({ commit }) {
    commit("clearAll");
  },
};

const mutations = {
  clearAll(state) {
    state.status = "";
    state.items = [];
    state.itemsCount = 0;
    state.itemsFreshness = 0;
  },
  getAllRequest(state) {
    state.status = "loading";
  },
  getAllSuccess(state, items) {
    state.status = "success";
    state.items = items;
    state.itemsCount = items.length;
    state.itemsFreshness = Date.now();
  },
  getAllFailure(state, error) {
    state.status = "error";
    state.error = error;
  },
};

const getters = {
  getSelectionList: (state) => () => {
    return state.items;
  },
};

export const businesses = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
