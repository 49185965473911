var render = function render(){var _vm=this,_c=_vm._self._c;return _c('viz-base-tile',{attrs:{"id":_vm.id,"data":_vm.data,"premised":_vm.premised,"manual":_vm.manual},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('cite',[_vm._v("I am a Logs widget #"+_vm._s(_vm.id)+" : "+_vm._s(_vm.data))])]},proxy:true},{key:"body",fn:function(){return [_c('b-card-text',[(_vm.logs.status === 'loading')?_c('em',[_c('font-awesome-icon',{attrs:{"icon":"spinner","size":"2x","spin":""}}),_vm._v("   "+_vm._s(_vm.$t("actions.t_loading"))+" ")],1):(_vm.logs.status === 'error')?_c('div',{staticClass:"m-3 bg-danger"},[_c('h1',[_vm._v("Could not get logs: "+_vm._s(_vm.logs.error)+"!")])]):(_vm.logs.status === 'success' && _vm.logs.items)?_c('div',[(_vm.logs.items.length > 0)?_c('div',[_c('b-table',{staticStyle:{"cursor":"pointer"},attrs:{"id":`table-logs-${_vm.id}`,"sticky-header":_vm.data.tableHeaderCss,"caption":_vm.data.tableCaption,"caption-top":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.logsList,"fields":_vm.fields,"striped":"","hover":"","responsive":_vm.data.tableResponsive},on:{"row-clicked":(item) => _vm.$set(item, '_showDetails', !item._showDetails)},scopedSlots:_vm._u([{key:"cell(estimated)",fn:function(data){return [(data.value)?_c('strong',[_vm._v(_vm._s(_vm.formatHuman(data.value)))]):_c('strong',[_vm._v(_vm._s(_vm.$t("logs.wait_planning")))]),_c('br'),_vm._v(" "+_vm._s(data.item.customer_description.substr(0, 100))+" ")]}},{key:"cell(maintenance)",fn:function(data){return [(data && data.value)?_c('b-img',{staticClass:"text-center",attrs:{"src":data.value.logo,"alt":data.value.name,"width":"50px"}}):_vm._e()]}},{key:"cell(end)",fn:function(data){return [_c('StatusBox',{attrs:{"level":data.item.level,"state":data.value !== null && data.value !== '',"maintenance":data.item.maintenance}})]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-list-group',{attrs:{"flush":""}},_vm._l((row.item),function(value,key){return (
                        _vm.isDisplayed(key) &&
                        value !== null &&
                        !(key === 'documents' && !value.length)
                      )?_c('b-list-group-item',{key:key},[(key === 'equipment')?_c('b-row',[_c('b-col',{staticClass:"text-sm-left",attrs:{"sm":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("logs.fields.equiplabel")))])]),_c('b-col',{staticClass:"text-sm-left"},[_vm._v(_vm._s(value.type.name))])],1):(key === 'level_label')?_c('b-row',[_c('b-col',{staticClass:"text-sm-left",attrs:{"sm":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("logs.fields.level_label")))])]),_c('b-col',{staticClass:"text-sm-left"},[_vm._v(_vm._s(value))])],1):(key === 'timestamp')?_c('b-row',[_c('b-col',{staticClass:"text-sm-left",attrs:{"sm":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("logs.fields.timestamp")))])]),_c('b-col',{staticClass:"text-sm-left"},[_vm._v(_vm._s(_vm.formatApiDate(value)))])],1):(key === 'end')?_c('b-row',[_c('b-col',{staticClass:"text-sm-left",attrs:{"sm":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("logs.fields.end")))])]),_c('b-col',{staticClass:"text-sm-left"},[_vm._v(_vm._s(_vm.formatApiDate(value)))])],1):(
                          key === 'documents' && value && value.length > 0
                        )?_c('b-row',[_c('b-col',{staticClass:"text-sm-left",attrs:{"sm":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("logs.fields.pictures")))])]),_c('b-col',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('photo-modal-' + row.item['id']),expression:"'photo-modal-' + row.item['id']"}],staticClass:"text-sm-left"},[_c('font-awesome-icon',{attrs:{"icon":"images","size":"1x"}}),_vm._v(" "+_vm._s(_vm.$t("logs.fields_content.pictures")))],1),_c('PhotoCarousel',{attrs:{"id":row.item['id'],"photos":value}})],1):(key === 'technician_comment')?_c('b-row',[_c('b-col',{staticClass:"text-sm-left",attrs:{"sm":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("logs.fields.tech_comment")))])]),_c('b-col',{staticClass:"text-sm-left"},[_vm._v(_vm._s(value))])],1):_c('b-row',[_c('b-col',{staticClass:"text-sm-left",attrs:{"sm":"4"}},[_c('strong',[_vm._v(_vm._s(_vm.$t(`logs.fields.${key}`)))])]),_c('b-col',{staticClass:"text-sm-left"},[_vm._v(_vm._s(value))])],1)],1):_vm._e()}),1)],1)]}}],null,false,2666661622)}),(_vm.logs.items && _vm.logs.items.length > _vm.perPage)?_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"hide-goto-end-buttons":"","size":"sm","aria-controls":`table-logs-${_vm.id}`,"align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1):_c('div',{staticClass:"text-warning text-center"},[_vm._v(" "+_vm._s(_vm.$t("logs.no_items"))+" ")])]):_c('div',{staticClass:"text-warning"},[_vm._v(" "+_vm._s(_vm.$t("logs.no_items"))+" ")])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }