const state = {
  // A notifications queue
  queue: [],
  // The maximum messages count stored in the queue
  max: 10,
  // A message specific for the login/register pages
  loginMessage: null,
};

const actions = {
  success({ commit }, message) {
    commit("success", message);
  },
  error({ commit }, message) {
    commit("error", message);
  },
  loginAlert({ commit }, message) {
    commit("loginAlert", message);
  },
  loginClear({ commit }, message) {
    commit("loginClear", message);
  },
};

const getters = {
  lastLoginMessage: (state) => {
    return state.loginMessage &&
      state.loginMessage !== "" &&
      state.loginMessage !== "undefined"
      ? state.loginMessage
      : "";
  },
};

const mutations = {
  success(state, message) {
    // Pushes a vue-notification message in the queue
    // See https://github.com/euvl/vue-notification
    state.queue.push({
      // title: "Success message",
      type: "success",
      duration: 5000,
      text: message,
    });

    if (state.queue.length > state.max) {
      // Perhaps alerting would be useful?
      state.queue.shift();
    }
  },
  error(state, message) {
    state.queue.push({
      // title: "Alert message",
      type: "error",
      // Very long duration to avoid making it mandatory clickable
      duration: 60000,
      text: message,
    });

    if (state.queue.length > state.max) {
      // Perhaps alerting would be useful?
      state.queue.shift();
    }
  },
  loginAlert(state, message) {
    state.loginMessage = message;
  },
  // eslint-disable-next-line no-unused-vars
  loginClear(state, message) {
    state.loginMessage = null;
  },
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
