
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { getPremisesTag, registerTagToPremise } from "@/_services/nfc.service";
import { format } from "date-fns";
import {
  createDateFromTimeInput,
  InterventionType,
  VisitMessage,
  VisitTag,
} from "@/_helpers/visits";
import { NfcState } from "@/_store/nfc.module";

enum States {
  INIT,
  PREMISE_TAG,
  ASSOCIATING_TAG,
  READ_TAG,
  READY_TO_WRITE_TAG,
  WRITE_TAG,
  ERROR,
}

// arrival departure
const interType = InterventionType.Maintenance;
const interTypeOptions = [
  { text: "Entretien", value: InterventionType.Maintenance },
  { text: "Dépannage", value: InterventionType.Repair },
];

export default defineComponent({
  name: "NFCRegister",
  props: {
    premises: {
      type: Object,
      default: null,
    },
  },
  emits: ["tagWritten"],
  data() {
    return {
      arrival: format(new Date(), "HH:mm"),
      departure: format(new Date(), "HH:mm"),
      interType: interType,
      interTypeOptions: interTypeOptions,
      premise_tag: "",
      // visitManager: new VisitManager(),
      state: States.INIT as States,
      isAssociatingTag: false,
      states: States,
      visits: [] as Array<VisitMessage>,
      nfcMessage: "",
    };
  },
  computed: {
    NfcState() {
      return NfcState;
    },
    ...mapState("NFCModule", {
      hasNFC: "hasNFC",
      nfcReaderState: "state",
      message: "message",
      tagId: "tagId",
      scanDate: "scanDate",
    }),
    InterventionType() {
      return InterventionType;
    },
    States() {
      return States;
    },
    isTagEqual(): boolean {
      console.log("isTagEqual", this.tagId, this.premise_tag);
      /** Check if the tag is the same as the premises tag */
      if (this.tagId === this.premise_tag) {
        return true;
      }
      return false;
    },
  },
  watch: {
    isTagEqual(val, oldVal) {
      console.log("isTagEqual", val, oldVal);
      if (val) {
        this.state = this.States.READY_TO_WRITE_TAG;
      }
    },
  },
  mounted: async function () {
    console.log("onMounted, Cleaning NFC messages");
    this.nfcMessage = "";
    this.state = States.INIT;
    this.$store.commit("NFCModule/clearNfcMessages");
    if (this.scanDate) {
      console.log("scanDate", this.scanDate);
      try {
        this.arrival = format(this.scanDate, "HH:mm");
      } catch (err) {
        console.log("err", err);
      }
    }
    await getPremisesTag(this.premises.id)
      .then((tag) => {
        // the tag is found
        console.log("tags", tag);
        this.premise_tag = tag.uid;
        this.state = States.PREMISE_TAG;
        this.readTagClick();
      })
      .catch((err) => {
        // 404 the tag is not associated to the premises
        console.log("err while loading tag data", err);
        this.premise_tag = "";
        this.state = States.PREMISE_TAG;
        this.readTagClick();
        // this.state = States.ERROR;
      });
  },
  methods: {
    ...mapActions("NFCModule", {
      readNfc: "readNfc",
      writeNfc: "writeNfc",
      stopNFC: "stopNfc",
    }),
    format,
    readTagClick: function () {
      console.log("readTagClick");

      this.readNfc().then((visit: VisitTag) => {
        console.log("visit", visit);
        if (visit.visits) {
          for (const visit1 of visit.visits.getVisits()) {
            console.log("visit1", visit1);
            this.visits.push(visit1);
          }
        }
        this.stopNFC();
      });
    },
    writeTagClick: function () {
      console.log(
        "writeTagClick",
        this.arrival,
        this.departure,
        this.interType
      );
      const arrivalDate = createDateFromTimeInput(new Date(), this.arrival);
      const departureDate = createDateFromTimeInput(new Date(), this.departure);
      const visit = new VisitMessage(
        arrivalDate,
        departureDate,
        this.interType
      );
      console.log("visit state", visit.toString());
      this.message.visits.addVisit(visit);
      console.log("visitManager", this.message.visits);
      this.state = this.states.WRITE_TAG;
      this.writeNfc(this.message.visits)
        .then((visit: VisitTag) => {
          console.log("visit", visit);
          this.$store.dispatch("NFCModule/clearScanDate");
          this.stopNFC();
          this.$emit("tagWritten", visit);
        })
        .catch((err) => {
          console.log("err while writing tag", err);
          // this.state = States.ERROR;
          this.nfcMessage = "Error while writing tag, please try again";
        });
    },
    updateTagAssociation: function () {
      console.log("updateTagAssociation");
      this.isAssociatingTag = true;
      registerTagToPremise(this.premises.id, this.tagId).then((response) => {
        if (response) {
          this.premise_tag = response;
        }
        console.log("registerTagToPremise done", response);
      });
    },
  },
});
