import { format } from "date-fns";
import { inventoryService } from "@/_services";
import { InventoryState, InventoryType } from "@/_services/inventory.service";
import { equipmentService } from "@/_services";
import { router } from "@/_helpers";

const equipmentState = ["Neuf", "Bon", "Moyen", "Dégradé", "Hors service"];

const state = {
  status: "",
  items: [],
  itemsCount: 0,
  itemsFreshness: 0,
  equipments: [],
  companyInventoryForms: {},
  companyInventoryFreshness: {},
};

const actions = {
  addEquipment({ dispatch, commit, rootState }, equipment) {
    commit("addEquipment", equipment);
  },

  updateEquipment({ dispatch, commit, rootState }, payload) {
    commit("updateEquipment", payload);
  },

  getInventory({ dispatch, commit, rootState }, premiseId, inventoryId) {
    commit("getAllRequest");

    // Get the equipments of the inventoried premises
    equipmentService
      .getAll({ premiseId: premiseId, inventory: true })
      .then(
        (data) => {
          console.log("[inventory] Got equipments:", data);

          // let locations = [];
          data.forEach((equipment) => {
            // const location =
            //   equipment.location === "" ? "Non localisé" : equipment.location;
            // console.log(`- ${equipment.name}, room: '${equipment.location}'`);
            // if (locations.indexOf(location) === -1) {
            //   locations.push(location);
            // }

            // Not yet marked as inventoried
            equipment.inventoried = false;
            // Not yet updated on the server
            equipment.updated = false;
            // to hide if removed
            equipment.displayed = true;
            // Prepare inventory information
            // 1 - nouveau
            // 2 - relevé
            // 3 - inexistant
            // 4 - supprimé
            equipment.inventory_type = InventoryType.MISSING;
            equipment.inventory_state = InventoryState.NOT_VALIDATED;
            equipment.inventory_date = null; //format(new Date(), "yyyy-MM-dd");
            equipment.inventory_nb = inventoryId;
            equipment.inventory_author = rootState.account.user.company;
          });
          // console.log("Got some existing locations:", locations);

          commit("getAllEquipmentsSuccess", data);
          dispatch("alert/success", router.app.$t("equipments.ok_message"), {
            root: true,
          });
        },
        (error) => {
          commit("getAllFailure", error);
          if (error && error !== "Unauthorized") {
            dispatch("alert/error", error, { root: true });
          } else {
            dispatch("account/userDenied", "Equipments", { root: true });
          }
        }
      )
      .then(() => {
        // then load the inventories
        inventoryService.getInventory(premiseId).then(
          (data) => {
            console.log("[inventory] Got last inventory:", data);
            commit("getAllSuccess", data);
            dispatch("alert/success", router.app.$t("equipments.ok_message"), {
              root: true,
            });
            data.forEach((inventory) => {
              dispatch("UpdateEquipmentInventory", inventory);
            });
          },
          (error) => {
            commit("getAllFailure", error);
            if (error && error !== "Unauthorized") {
              dispatch("alert/error", error, { root: true });
            } else {
              dispatch("account/userDenied", "inventory", { root: true });
            }
          }
        );
      });
  },
  UpdateEquipmentInventory({ state, commit }, inventory) {
    if (state.equipments.length === 0) {
      return;
    }
    commit("UpdateEquipmentInventory", inventory);
  },
  flushInventory({ state, dispatch, commit, rootState, rootGetters }) {
    commit("flushRequest");
    // console.log("[Ibrnyoty ] Equipments: ", state.equipments);
    state.equipments.forEach((eqp) => {
      /*
      console.log(eqp.name);
      console.log(" ", eqp.inventoried);
      console.log(" ", eqp.present);
      console.log(" ", eqp.updated);
      console.log(" ", eqp.new);
      // if (!eqp.inventoried) continue;
       */
      if (eqp.new) {
        // create an equipment, the equipment creation
        // will also allow to create the inventory at the same time.
        // Set the numerical value of the equipment type
        eqp.type = eqp.type_id;
        equipmentService.create(eqp);
        commit("updateLastInventoryDate", {
          equipmentName: eqp.name,
          date: eqp.inventory_date,
        });
      } else if (eqp.inventoried) {
        const inventoryDate = format(new Date(), "yyyy-MM-dd");
        // if there's a problem, still send the metadata of the equipment
        let cleanMeta = { ...eqp.metadata };

        // Retrieve the form with the metadata field name
        const form = rootGetters["equipments/getEquipmentForm"](eqp.type_id);

        // found form with metadata
        if (form !== undefined && form !== null) {
          // reset before sending
          cleanMeta = {};
          form.fields.forEach((field) => {
            // As discussed, only copy existing metadata, drop unknown meta
            if (field.name in eqp.metadata) {
              cleanMeta[field.name] = eqp.metadata[field.name];
            } else {
              // put empty value to overwrite
              cleanMeta[field.name] = "";
            }
          });
        } else {
          // No form ? just copy
          cleanMeta = eqp.metadata;
        }

        // create an inventory
        const inventoryPayload = {
          type: eqp.inventory_type,
          state: InventoryState.NOT_VALIDATED,

          // Force the date to today prevent empty/old date
          date: inventoryDate,
          nb: eqp.inventory_nb,
          metadata: cleanMeta,
        };
        inventoryService.createInventory(eqp.id, inventoryPayload);
        commit("updateLastInventoryDate", {
          equipmentName: eqp.name,
          date: inventoryDate,
        });
      }
    });
    // TODO : What to do ?!?
  },

  loadCompanyForm: ({ commit, dispatch }, companyId) => {
    console.log("Called fill inventory form with company", companyId);
    try {
      inventoryService
        .getCompanyForm(companyId)
        .then((response) => response.data)
        .then((forms) => {
          commit("SetCompanyForm", { companyId, forms });
        });
    } catch (e) {
      console.error("Have an error: ", e);
    }
  },
  getBusinessForm: ({ state }, businessId) => {
    console.log("Fetching business form for: ", businessId);
    return state.companyInventoryForms[businessId];
  },

  clearAll({ commit }) {
    commit("clearAll");
  },
  clearForms({ commit }) {
    commit("clearForms");
  },
};

const mutations = {
  addEquipment(state, equipment) {
    console.log("[inventory] adding equipment: ", equipment);
    state.equipments.push(equipment);
  },
  updateEquipment(state, payload) {
    console.log("[inventory] updating equipment: ", payload.id, payload);
    // Nothing to do... update is automatic!
    const found = state.equipments.find((item) => item.id === payload.id);
    if (found) {
      console.log("[inventory] found!");
      found.updated = true;
    }
  },
  updateLastInventoryDate(state, { equipmentName, date }) {
    const found = state.equipments.find((item) => item.name === equipmentName);
    if (found) {
      found.inventory_date = date;
    }
  },
  UpdateEquipmentInventory(state, inventory) {
    const found = state.equipments.find(
      (item) => item.id === inventory.equipment_id
    );
    // console.log("[inventory] Equipment to up found ? ", found.name, inventory);
    if (found) {
      if (
        found.inventory_date === undefined ||
        found.inventory_date === null ||
        found.inventory_date <= inventory.date // update if same date
      ) {
        found.inventory_date = inventory.date;
        found.inventory_type = inventory.type;
        // update the metadata with object expansion
        // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Op%C3%A9rateurs/Syntaxe_d%C3%A9composition
        if (inventory.metadata) {
          found.metadata = { ...found.metadata, ...inventory.metadata };
        }
        // update display state
        switch (inventory.type) {
          case InventoryType.DELETED:
          case InventoryType.MISSING:
            console.log("Undisplay");
            found.displayed = false;
            break;
          default:
            found.displayed = true;
        }
      }
    }
  },
  clearAll(state) {
    state.status = "";
    state.items = [];
    state.itemsCount = 0;
    state.itemsFreshness = 0;
  },
  clearForms(state) {
    state.companyInventoryForms = {};
    state.companyInventoryFreshness = {};
  },
  getAllRequest(state) {
    state.status = "loading";
  },
  getAllSuccess(state, items) {
    state.status = "success";
    state.items = items;
    state.itemsCount = items.length;
    state.itemsFreshness = Date.now();
  },
  getAllEquipmentsSuccess(state, items) {
    state.status = "equipments";
    state.equipments = items.map((equipment) => {
      const existing = state.equipments.find(
        (item) => item.id === equipment.id
      );
      if (!existing) {
        return equipment;
      }
      return existing.updated ? existing : equipment;
    });
  },
  getAllFailure(state, error) {
    state.status = "error";
    state.error = error;
  },
  flushRequest(state) {
    state.status = "flush";
    state.error = null;
  },
  SetCompanyForm: (state, { companyId, forms }) => {
    state.companyInventoryForms[companyId] = forms;
    state.companyInventoryFreshness[companyId] = Date.now();
  },
};

const getters = {
  displayedEquipments: (state) => {
    // console.log("[Vue inventory]", state.equipments.filter((eqp) => eqp.displayed));
    return state.equipments.filter((eqp) =>
      // prevent problem while upgrading / not treated inventory
      eqp.displayed === undefined ? true : eqp.displayed
    );
  },
  // eslint-disable-next-line no-unused-vars
  getUsage: (state, getters) => (usage) => {
    return [
      router.app.$t("inventory.usage.1"),
      router.app.$t("inventory.usage.2"),
      router.app.$t("inventory.usage.3"),
    ][usage - 1];
  },
  // eslint-disable-next-line no-unused-vars
  getInventoryType: (state, getters) => (type) => {
    return [
      router.app.$t("inventory.type.1"),
      router.app.$t("inventory.type.2"),
      router.app.$t("inventory.type.3"),
      router.app.$t("inventory.type.4"),
    ][type - 1];
  },
};

export const inventory = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
