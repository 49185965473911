/*
 * Business services
 */

import { backendConfig } from "@/_helpers";
import { _axios } from "@/plugins/axios";

// Set only one value to hide the select box in the Premises component
const fakeBusinesses: { text: string; value: string }[] = [
  {
    value: "Maisons & Cités",
    text: "Maisons & Cités",
  },
];

export const businessService = {
  getAll,
};

function getAll() {
  console.log("[businesses] getting all businesses...");

  return _axios
    .get(`${backendConfig.businessesEndpoint}`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 404) {
        // fixme: test only while API is not yet implemented!
        console.error(
          `GET ${backendConfig.businessesEndpoint}, 404, faka data: `,
          fakeBusinesses
        );
        return Promise.resolve(fakeBusinesses);
      }
    });
}
