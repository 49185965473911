<template>
  <viz-base-tile :id="id" :data="data" :premised="premised" :manual="manual">
    <template #name>
      <cite>I am a Documents widget #{{ id }} : {{ data }}</cite>
    </template>

    <template #body>
      <b-card-text class="text-center">
        <em v-if="documents.status === 'loading'">
          <font-awesome-icon icon="spinner" size="2x" spin></font-awesome-icon>
          &nbsp; {{ $t("actions.t_loading") }}
        </em>
        <div v-else-if="documents.status === 'error'" class="m-3 bg-danger">
          <h1>Could not get documents: {{ documents.error }}!</h1>
        </div>
        <div v-else-if="documents.status === 'success' && documents.items">
          <div v-if="documents.items.length > 0">
            <b-table
              :id="`table-documents-${id}`"
              :sticky-header="data.tableHeaderCss"
              :caption="data.tableCaption"
              caption-top
              :per-page="perPage"
              :current-page="currentPage"
              :items="documents.items"
              :fields="fields"
              primary-key="name"
              :responsive="data.tableResponsive"
            >
              <!-- A custom formatted column -->
              <template #cell(name)="data">
                <div class="align-middle">
                  <b-link
                    v-if="data.item.url"
                    :href="data.item.url"
                    target="_blank"
                  >
                    <div>
                      <font-awesome-icon
                        icon="file-alt"
                        size="4x"
                      ></font-awesome-icon>
                    </div>
                    <div>
                      <strong class="text-primary">{{
                        data.item.description
                      }}</strong>
                    </div>
                  </b-link>
                </div>
              </template>
            </b-table>

            <b-pagination
              v-if="documents.items && documents.items.length > perPage"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              hide-goto-end-buttons
              size="sm"
              :aria-controls="`table-document-${id}`"
              align="right"
            ></b-pagination>
          </div>
          <div v-else class="text-warning">
            {{ $t("documents.no_items") }}
          </div>
        </div>
        <div v-else class="text-warning">No known documents!</div>
      </b-card-text>
    </template>
  </viz-base-tile>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "VizDocuments",
  components: {
    VizBaseTile: () => import("./BaseTile"),
  },
  props: {
    id: {
      type: String,
      default: "1",
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    premised: {
      type: Boolean,
      default: false,
    },
    manual: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 500,
      // The list of most useful fields
      fields: [
        {
          key: "name",
          label: this.$t("documents.fields.name"),
        },
        // {
        //   key: "url",
        //   label: this.$t("documents.fields.url")
        // },
        // {
        //   key: "category",
        //   label: this.$t("documents.fields.category")
        // },
        // {
        //   key: "description",
        //   label: this.$t("documents.fields.description")
        // }
      ],
    };
  },
  computed: {
    ...mapState({
      documents: (state) => state.documents,
      chosenPremise: (state) => state.premises.selected,
    }),
    rows() {
      return this.documents.items.length;
    },
  },
  watch: {
    // Changed the premise to inventory
    chosenPremise: function (newVal, oldVal) {
      if (!this.premised) {
        return;
      }

      if (newVal === null) {
        // No more selected premise
        console.log("[documents] no more selected premise");
        this.clearAll();
      } else {
        // Newly selected premise
        // Get all the available documents for the selected premise
        console.log("[documents] fetching...");
        this.getAll(this.chosenPremise.name);
      }
    },
  },
  created() {
    console.log(
      `[documents] Premised: ${this.premised}, chosen: '${
        this.chosenPremise && this.chosenPremise.name
      }' Manual: ${this.manual}`
    );

    if (this.manual) {
      console.log("Manul mode");
      return;
    }

    if (!this.premised || (this.premised && this.chosenPremise)) {
      console.log("[documents] getting all documents");
      // Get all the available documents
      this.getAll();
    }
  },
  methods: {
    ...mapActions("documents", ["getAll", "clearAll"]),
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Some useful documents"
  },
  "fr": {
    "title": "Quelques documents utiles"
  }
}
</i18n>

<style>
table > thead {
  display: none !important;
}
</style>
